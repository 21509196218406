import { useEffect, useState } from "react";

import { GetContactData, PostJuneHomesBookingData } from "../../api/helper";

import {
  JUNE_HOMES_CITIES_CODE_MAP,
} from "../../constants/juneHomes";

import "./styles/form.css";

const JuneHomesForm = ({ params }) => {
  const [formStage, setFormStage] = useState(1);
  const [contactData, setContactData] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    crmId: params.crmId,
    city: "",
    propertyName: "",
    moveInDate: "",
  });

  useEffect(() => {
    async function fetchData() {
      const contactResponse = await GetContactData(params.crmId);
      setLoading(false);
      if (contactResponse.status === "success") {
        setContactData(contactResponse.data);
      } else {
        setError(contactResponse.data);
      }
    }
    fetchData();
  }, [params]);

  const activeForm = {
    1: (
      <BookingForm
        crmId={params.crmId}
        setFormStage={setFormStage}
        formData={formData}
        setFormData={setFormData}
      />
    ),
    2: (
      <Preview
        contactData={contactData}
        setFormStage={setFormStage}
        setError={setError}
        crmId={params.crmId}
        formData={formData}
        setFormData={setFormData}
      />
    ),
    3: <Thankyou />, //selectedProperty={selectedProperty}
  }[formStage];

  return (
    <>
      <div className="header">
        <img
          alt="logo"
          className="logo"
          src="https://cdn.universityliving.com/logo/newLogo.png"
        />
      </div>
      <div className="form-parent">
        <div className="main-form">
          {loading ? (
            <p className="loading">Loading....</p>
          ) : error ? (
            <p className="error">Error: {error}</p>
          ) : (
            activeForm
          )}
        </div>
      </div>
    </>
  );
};

export default JuneHomesForm;

const BookingForm = ({ crmId, setFormStage, formData, setFormData }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const isFormComplete =
    formData.crmId &&
    formData.propertyName &&
    formData.city &&
    formData.moveInDate; 
  return (
    <div className="booking-form">
      <h1>June Homes Booking Form</h1>
      <div className="form-row">
        <p className="input-label">CRM Contact Id</p>
        <input
          className="input"
          type="text"
          placeholder="Enter CRM ID"
          value={crmId}
          disabled
        />
      </div>
      <div className="form-row">
        <p className="input-label">Property Name*</p>
        <input
          className="input"
          type="text"
          onChange={handleInputChange}
          name="propertyName"
          value={formData.propertyName}
        />
      </div>
      <div className="form-row">
        <p className="input-label">City*</p>
        <select
          onChange={handleInputChange}
          className="select-input"
          defaultValue="default"
          name="city"
        >
          <option disabled value="default">
            Select one option
          </option>
          {Object.entries(JUNE_HOMES_CITIES_CODE_MAP).map(([city, code]) => (
            <option key={code} value={code}>
              {city}
            </option>
          ))}
        </select>
      </div>
      <div className="form-row">
        <p className="input-label">Move-In Date*</p>
        <input
          className="input"
          type="date"
          onChange={handleInputChange}
          name="moveInDate"
          value={formData.moveInDate}
        />
      </div>

      <div className="form-row">
        <p className="input-label">Move-Out Date</p>
        <input
          className="input"
          type="date"
          onChange={handleInputChange}
          name="moveOutDate"
          value={formData.moveOutDate}
        />
      </div>
      <div className="btn-parent">
        <button
          disabled={!isFormComplete}
          onClick={() => setFormStage(2)}
          className={
            isFormComplete ? "form-submit-btn" : "form-submit-btn-disabled"
          }
        >
          Preview
        </button>
      </div>
    </div>
  );
};

const Preview = ({
  formData,
  setFormData,
  contactData,
  setFormStage,
  setError,
  crmId,
}) => {
  const [sendingMail, setSendingMail] = useState(false);
  const handleFormSubmit = async () => {
    const payload = {
      firstName: contactData?.First_Name,
      lastName: contactData?.Last_Name,
      email: contactData?.Email,
      phone: contactData?.Mobile,
      city: formData?.city,
      move_in: formData?.moveInDate,
      move_out: formData?.moveOutDate,
      "renters_message": formData?.propertyName,
      crmid:crmId
    };
    setSendingMail(true);
    const mailResponse = await PostJuneHomesBookingData(payload);
    console.log(mailResponse);
    setSendingMail(false);
    if (mailResponse.status !== "success") {
      setError(mailResponse.data);
      return;
    }
    setFormStage(3);
  };

  return (
    <>
      <div className="preview-header">
        Preview Details
      </div>

      <div className="preview-field-container">
        <div className="preview-field">First Name:</div>
        <div className="preview-field">{contactData?.First_Name}</div>
      </div>
      <div className="preview-field-container">
        <div className="preview-field">Last Name:</div>
        <div className="preview-field">{contactData?.Last_Name}</div>
      </div>
      <div className="preview-field-container">
        <div className="preview-field">Phone:</div>
        <div className="preview-field">{contactData?.Mobile}</div>
      </div>
      <div className="preview-field-container">
        <div className="preview-field">Email:</div>
        <div className="preview-field">{contactData?.Email}</div>
      </div>
      <div className="preview-field-container">
        <div className="preview-field">City:</div>
        <div className="preview-field">{formData?.city}</div>
      </div>
      <div className="preview-field-container">
        <div className="preview-field">Property ID:</div>
        <div className="preview-field">{formData.propertyName}</div>
      </div>
      <div className="preview-field-container">
        <div className="preview-field">Move-In Date:</div>
        <div className="preview-field">{formData.moveInDate}</div>
      </div>
      <div className="preview-field-container">
        <div className="preview-field">Move-Out Date:</div>
        <div className="preview-field">{formData.moveOutDate}</div>
      </div>
      <div className="btn-preview">
        <button
          disabled={sendingMail}
          onClick={handleFormSubmit}
          className="form-submit-btn"
        >
          {sendingMail ? "Sending...." : "Submit"}
        </button>
      </div>
    </>
  );
};

const Thankyou = ({ selectedProperty }) => {
  return (
    <>
      <h1>Lead Sent Successfully!</h1>
    </>
  );
};
