import Axios from 'axios';

const ApiServer = Axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 30000,
});

export const GetBotBookings = async (bookingId) => {
  try {
    const response = await ApiServer.get('bot-booking/bot-booking', {
      params: {
        bookingId,
      },
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const GetZohoBookingsContacts = async (bookingId) => {
  try {
    const response = await ApiServer.get('bot-booking/zoho-bookings-contacts', {
      params: {
        bookingId,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const PostBotBookings = async (data) => {
  try {
    const response = await ApiServer.post('bot-booking/save-bot-booking', data);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const GetScionProperties = async () => {
  try {
    const response = await ApiServer.get('bot-booking/scion-property-list');
    return response.data;
  } catch (error) {
    return {
      status: 'failed',
      data: error.response?.data?.message || 'something went wrong',
    };
  }
};

export const GetContactData = async (contactId) => {
  try {
    const response = await ApiServer.get(
      `bot-booking/scion-contact/${contactId}`
    );
    return response.data;
  } catch (error) {
    return {
      status: 'failed',
      data: error.response?.data?.message || 'something went wrong',
    };
  }
};

export const PostScionBookingData = async (data) => {
  try {
    const response = await ApiServer.post('bot-booking/send-scion-mail', data);
    return response.data;
  } catch (error) {
    return {
      status: 'failed',
      data: error.response?.data?.message || 'something went wrong',
    };
  }
};

export const PostJuneHomesBookingData = async (data) => {
  try {
    const response = await ApiServer.post('bot-booking/send-june-home-data', data);
    return response.data;
  } catch (error) {
    return {
      status: 'failed',
      data: error.response?.data?.message || 'something went wrong',
    };
  }
};
